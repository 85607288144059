import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/plugins/_conf";
import "@/registerServiceWorker";

import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
