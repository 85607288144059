export default {
  namespaced: true,
  state: {
    layout: "layout-claro",
    loading: false,
    relatorioLoading: false,
    menuAtivado: true,
    relatorioLocal: false,
    modal: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: "",
    },
    modalRetorno: {
      modalAtivado: false,
      tituloModal: "",
      mensagemModal: "",
      tipoModal: "",
    },
    modalBloqueia: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: "",
    },
    modalModerar: {
      modalAtivado: false,
      mensagemModal: "",
      cuponsModal: [],
      sucesso: false,
      usuarioId: "",
      idHistoricoPremiacaoIsntantanea: "",
    },
    modalAlerta: {
      modalAtivado: false,
      mensagemModal: "",
      tipoModal: "",
    },
  },
  mutations: {
    SET_TEMA(state, layout) {
      state.layout = layout;
    },
    SET_LOADING(state, valor) {
      state.loading = valor;
    },
    SET_SIDEBAR(state, valor) {
      state.menuAtivado = valor;
    },
    SET_MODAL(state, modal) {
      state.modal.modalAtivado = modal.ativado;
      state.modal.mensagemModal = modal.mensagem;
      state.modal.tipoModal = modal.tipo;
    },
    SET_MODAL_RETORNO(state, modal) {
      state.modalRetorno = {
        modalAtivado: true,
        ...modal
      }
    },
    SET_MODAL_ALERTA(state, modal) {
      state.modalAlerta.modalAtivado = modal.ativado;
      state.modalAlerta.mensagemModal = modal.mensagem;
      state.modalAlerta.tipoModal = modal.tipo;
    },
    FECHAR_MODAL_RETORNO(state, boolean) {
      state.modalRetorno.modalAtivado = boolean;
    },
    SET_RELATORIO_LOCAL(state, relatorioLocal) {
      state.relatorioLocal = relatorioLocal;
    },
    SET_LOADING_RELATORIO(state, valor) {
      state.relatorioLoading = valor;
    },
    SET_MODAL_BLOQUEIO(state, modal) {
      state.modalBloqueia.modalAtivado = modal.ativado;
      state.modalBloqueia.mensagemModal = modal.mensagem;
      state.modalBloqueia.tipoModal = modal.tipo;
    },
    SET_MODAL_MODERAR(state, modal) {
      state.modalModerar.modalAtivado = modal.ativado;
      state.modalModerar.mensagemModal = modal.mensagem;
      state.modalModerar.cuponsModal = modal.cupons;
      state.modalModerar.sucesso = modal.sucesso;
      state.modalModerar.usuarioId = modal.usuarioId;
      state.modalModerar.idHistoricoPremiacaoIsntantanea =
        modal.idHistoricoPremiacaoIsntantanea;
    },
  },
};
