import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
// componentes do bundle
import Login from "@/views/Login.vue";
import LayoutLogado from "@/views/_LayoutLogado.vue";
import Dashboard from "@/views/Dashboard.vue";
Vue.use(VueRouter);

// Função auxiliar separa hash e token
function getHashAndToken(hash) {
  const [hashValue, queryParams] = hash.split('?')
  const token = new URLSearchParams(queryParams).get('code')
  return { hashValue, token }
}

// Exporta em arquivo à parte e carrega a View somente quando é solicitado
function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`);
}

const routes = [
  {
    path: "/",
    component: LayoutLogado,
    children: [
      {
        path: "/",
        name: "inicio",
        component: Dashboard,
        meta: {
          precisaAutenticacao: true,
          perfilPermitido: [1, 2, 3],
          menuInterno: {
            texto: "Início",
            icone: "IconInicio",
          },
        },
      },
      {
        path: "/cupons",
        name: "cupons",
        component: lazyLoad("Cupom"),
        meta: {
          precisaAutenticacao: true,
          perfilPermitido: [1, 2],
          menuInterno: {
            texto: "Cupons",
            icone: "IconCupom",
          },
        },
      },
      {
        path: "/cupons-moderados",
        name: "cupons-moderados",
        component: lazyLoad("CupomModerado"),
        meta: {
          precisaAutenticacao: true,
          perfilPermitido: [1, 2],
          menuInterno: {
            texto: "Cupons Moderados",
            icone: "IconCupom",
          },
        },
      },
      {
        path: "/relatorio-usuarios",
        name: "relatorio-usuarios",
        component: lazyLoad("Usuarios"),
        meta: {
          precisaAutenticacao: true,
          perfilPermitido: [1, 2],
          menuInterno: {
            texto: "Gestão de Usuários",
            icone: "IconRelatorioUsuarios",
          },
        },
      },
      {
        path: "/numero-sorte",
        name: "numero-sorte",
        component: lazyLoad("NumeroSorte"),
        meta: {
          precisaAutenticacao: true,
          perfilPermitido: [1, 2],
          menuInterno: {
            texto: "Número da Sorte",
            icone: "IconNumeroSorte",
          },
        },
      },
      
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

function semValidacao(to) {
  const token = localStorage.getItem("token");
  return (
    to.meta.precisaAutenticacao == true &&
    !store.state.user.estaLogado &&
    !token
  );
}

function verificarPermissoes(to) {
  const perfilId = JSON.parse(localStorage.getItem("perfilId"));
  if (
    to.meta.perfilPermitido &&
    !to.meta.perfilPermitido.some((item) => item == perfilId)
  ) {
    return true;
  }
}

router.beforeEach((to, from, next) => {
  const { hashValue, token } = getHashAndToken(to.hash)

  // Email redefinição de senha
  if (hashValue === '#2fa' && token) {
    window.localStorage.setItem("token", token);
  }
  if (semValidacao(to)) {
    return next("/login");
  }
  if (verificarPermissoes(to)) {
    return next("/");
  }
  next();
});

export default router;
