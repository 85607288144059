export default {
  namespaced: true,
  state: {
    telaAtivada: "Lista",
    linkAtivado: "",
    componenteAtivado: "",
    formularioTipo: "Novo",
    formularioEditarId: "",
    tabela: "",
    tabelaDetalhe: "",
    statuses: "",
    produtosModerar: "",
    relatorio: [],
    dataCupom: {},
    paginacao: "",
    produtoPesquisado: [],
    produtoSelecionado: false,
    produtosCadastrados: [],
    motivoReprova: []
  },
  mutations: {
    SET_CUPOM_TELA(state, tela) {
      state.telaAtivada = tela;
    },
    SET_LINKATIVADO(state, link) {
      state.linkAtivado = link;
    },
    SET_COMPONENTE(state, componente) {
      state.componenteAtivado = componente;
    },
    SET_TIPO_FORMULARIO(state, tipo) {
      state.formularioTipo = tipo;
    },
    SET_ID_FORMULARIO_EDITAR(state, id) {
      state.formularioEditarId = id;
    },
    SET_TABELA(state, dados) {
      state.tabela = dados;
    },
    SET_TABELA_DETALHE(state, dados) {
      state.tabelaDetalhe = dados;
    },
    SET_RELATORIO(state, relatorio) {
      state.relatorio = relatorio;
    },
    SET_STATUS_ITENS(state, dados) {
      state.statuses = dados;
    },
    SET_PRODUTOS_MODERAR(state, dados) {
      state.produtosModerar = dados;
    },
    SET_DATA_CUPOM(state, dados) {
      state.dataCupom = dados;
    },
    SET_DATA_PAGINACAO(state, dados) {
      state.paginacao = dados;
    },
    SET_PRODUTO_PESQUISADO(state, pesquisa) {
      state.produtoPesquisado.push(pesquisa);
      console.log(pesquisa)
    },
    SET_PRODUTO_PESQUISADO_LIMPO(state, pesquisa) {
      state.produtoPesquisado.pop(pesquisa);
    },
    SET_MOTIVO_REPROVA(state, motivos) {
      state.motivoReprova = motivos;
    },
  },
};
