export function emailLoginErro() {
  let erro = "";
  const email = this.$v.login.email;
  if (!email.$dirty) {
    return erro;
  }
  if (!email.required) {
    erro = "E-mail é obrigatório";
  } else if (!email.email) {
    erro = "Insira um e-mail válido";
  }
  return erro;
}
export function senhaLoginErro() {
  let erro = "";
  const senha = this.$v.login.senha;
  if (!senha.$dirty) {
    return erro;
  }
  if (!senha.required) {
    erro = "Senha é obrigatória";
  }
  return erro;
}
export function emailEsqueciErro() {
  let erro = "";
  const email = this.$v.emailEsqueci;
  if (!email.$dirty) {
    return erro;
  }
  if (!email.required) {
    erro = "E-mail é obrigatório";
  } else if (!email.email) {
    erro = "Insira um e-mail válido";
  }
  return erro;
}
