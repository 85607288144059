<template>
  <div>
    <CompHeader />
    <div class="container-interna" :class="{ desactive: !menuAtivo }">
      <Sidebar class="container-sidebar" />
      <transition mode="out-in" name="fade">
        <router-view class="container-conteudo"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import CompHeader from "@/components/header/Header.vue";
import Sidebar from "@/components/sidebar/Sidebar.vue";
export default {
  name: "interna",
  components: {
    CompHeader,
    Sidebar
  },
  computed: {
    menuAtivo() {
      return this.$store.state.interacoes.menuAtivado;
    }
  }
};
</script>

<style lang="scss" scoped>
.container-interna {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(100vh - 70px);
  .container-sidebar {
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    width: 280px;
    height: 100%;
    transition: 0.3s;
    overflow-x: hidden;
    z-index: 10;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  }
  .container-conteudo {
    position: absolute;
    top: 0;
    right: 0;
    overflow-x: hidden;
    width: calc(100% - 280px);
    height: 100%;
    transition: 0.3s;
    box-sizing: border-box;
  }
  &.desactive {
    .container-sidebar {
      width: 60px;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    }
    .container-conteudo {
      width: calc(100% - 60px);
    }
  }
  @media (max-width: 700px) {
    height: calc(100vh - 55px);
    .container-sidebar {
      z-index: 90;
      width: 280px;
      // box-shadow: 5px 20px 10px rgba(0, 0, 0, 0.1);
    }
    .container-conteudo {
      width: calc(100% - 60px);
    }
    &.desactive {
      .container-sidebar {
        width: 60px;
        // box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      }
      .container-conteudo {
        width: calc(100% - 60px);
      }
    }
  }
}
</style>
