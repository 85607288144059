// CAMINHOS API
export function _imagemMobile(img) {
  return `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Comunicacao/ImagemMobile/${img}`;
}
export function _imagemDesktop(img) {
  return `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Comunicacao/ImagemDesktop/${img}`;
}
export function _imagemCupom(img) {
  return `${process.env.VUE_APP_API_BASE}/wwwroot/Content/Upload/UploadCupom/${img}`;
}
//
// LOGIN
export function _rotaLogin() {
  return "/Autenticacao/AutenticarAdmin";
}
export function _rotaEsqueciSenha() {
  return "/Authentication/LoginPainel";
}
//
// ############ COMUNICAÇÃO ############
//
// COMUNICAÇÃO / BANNERS
export function _rotaBuscarBanners() {
  return "/Comunicacao/ObterTodos";
}
export function _rotaBuscarBannerPorId() {
  return "/Comunicacao/ObterPorId";
}
export function _rotaExcluirBanners() {
  return "/Comunicacao/Inativar";
}
export function _rotaAtualizarBanners() {
  return "/Comunicacao/Atualizar";
}
export function _rotaEnviarBanners() {
  return "/Comunicacao/Adicionar";
}
//
// COMUNICAÇÃO / FIQUE LIGADO
export function _rotaBuscarFiqueLigado() {
  return "Fique Ligado";
}
export function _rotaExcluirFiqueLigado() {
  return "Fique Ligado";
}
export function _rotaAtualizarFiqueLigado() {
  return "Fique Ligado";
}
export function _rotaEnviarFiqueLigado() {
  return "Fique Ligado";
}
//
// COMUNICAÇÃO / POPUP
export function _rotaBuscarPopup() {
  return "PopUp";
}
export function _rotaExcluirPopup() {
  return "PopUp";
}
export function _rotaAtualizarPopup() {
  return "PopUp";
}
export function _rotaEnviarPopup() {
  return "PopUp";
}
//
// CUPONS
export function _rotaCadastrarCupom() {
  return "/Cupom/Cadastrar";
}
export function _rotaAtualizarCupom() {
  return "/Cupom/Atualizar";
}
export function _rotaListarCupomStatus() {
  return "/Status/Listar";
}
export function _rotaListarCupomPorStatus() {
  return "/Cupom/Listar";
}
export function _rotaListarCupomObterDetalhes() {
  return "/Participacao/ConsultarDetalheParticipacao";
}
export function _rotaListarTodosCupons() {
  return "/Participacao/ConsultarParticipacoes";
}
export function _rotaListarTodosCuponsModerados() {
  return "/Participacao/ConsultarPremiosAprovadosReprovados";
}
export function _rotaListarTodosCuponsCompletos() {
  return "/Cupom/ListarTodosCompleto";
}
export function _rotaListarProdutosPorCupom() {
  return "/CupomProduto/ListarProdutosPorCupom";
}
export function _rotaListarMotivoReprovaCupom() {
  return "/MotivoReprova/Listar";
}
//
// PDV
export function _rotaListarPdv() {
  return "/UsuarioLojista/ListarResgatesAdmin";
}
// USUÁRIO
export function _rotaListarUsuarios() {
  return "/Usuario/ListarUsuariosAdmin";
}
export function _rotaListarPremiacaoInstantanea() {
  return "/UsuarioParticipante/ListarPremiacaoInstantanea";
}
export function _rotaHistoricoPremiacaoInstantanea() {
  return "/HistoricoPremiacaoInstantanea/ModerarPremioInstantaneo";
}
export function _rotaAtualizarStatusPremiacaoInstantanea() {
  return "/HistoricoPremiacaoInstantanea/AtualizarStatusPremiacaoInstantaneaLiberada";
}
export function _rotaBloquearUsuario(idUsuario, motivoBloqueio) {
  return `/Usuario/Bloquear?idUsuario=${idUsuario}&motivoBloqueio=${motivoBloqueio}`;
}
export function _rotaInativarUsuario() {
  return `/Usuario/InativarUsuarioAdmin`;
}
export function _rotaDesbloquearUsuario(idUsuario) {
  return `/Usuario/Desbloquear?idUsuario=${idUsuario}`;
}
export function _rotaModerar(
  idUsuario,
  idUsuarioAdmin,
  descricao,
  isBloqueado
) {
  return `/Usuario/Moderar?idUsuario=${idUsuario}&idUsuarioAdmin=${idUsuarioAdmin}&descricao=${descricao}&isBloqueado=${isBloqueado}`;
}
export function _rotaInformarGanhador() {
  return `/UsuarioParticipante/InformarGanhadorSorteio`;
}
export function _rotaObterDadosModeracao(idUsuario) {
  return `/Usuario/ConsultarUsuarioAdmin/${idUsuario}`;
}
//
// SAC
export function _rotaListarSac() {
  return "/Sac/Listar";
}
export function _rotaListarSacStatus() {
  return "/Sac/ListarSacStatus";
}
export function _rotaListarSacAssuntos() {
  return "/Sac/ListarSacAssunto";
}
export function _rotaAtualizarStatusOcorrencia() {
  return "/Sac/AtualizarStatusOcorrencia";
}
//
// NUMERO
export function _rotaListarNumeros() {
  return "/Participacao/ConsultarNumerosSorte";
}

//Upload premiacao
export function _rotaEnviarPlanilhaPremiacao() {
  return "/Upload/EnviarPlanilhaPremiacaoInstantanea";
}

//Agenda Premiacao
export function _rotaVerificaPlanilha() {
  return "/AgendaPremiacao/ExisteAgenda";
}

//Relatorios

export function _rotaGerarRelatorioGanhadores() {
  return "/Relatorio/Ganhadores";
}
export function _rotaGerarRelatorioUsuarios() {
  return "/Relatorio/Usuarios";
}

export function _rotaGerarRelatorioPdv() {
  return "/Relatorio/Lojistas";
}
//  export function _rotaEsqueciSenha(email) {
//   return `/Authentication/EsqueciMinhaSenha?email=${email}`;
// }
export function _rotaGerarRelatorio() {
  return "/Relatorio/NumerosDaSorte";
}
export function _rotaGerarRelatorioCupons() {
  return "/Relatorio/Cupons";
}
export function _rotaGerarRelatorioCuponsTotal() {
  return "/Relatorio/CupomTotal";
}
