<template>
  <div>
    <nav class="container-sidebar">
      <LinkSidebar
        v-for="(link, i) in linkSidebar"
        :key="i"
        :texto="link.meta.menuInterno.texto"
        :link="link.path"
        :icone="link.meta.menuInterno.icone"
      />
      <div class="menu-lateral-btn" @click="toggleMenu" :class="{ 'menu-lateral-fechado': !menuAtivo }">
        <span></span>
      </div>
    </nav>
  </div>
</template>

<script>
import LinkSidebar from "@/components/sidebar/LinksSidebar.vue";
export default {
  components: {
    LinkSidebar
  },
  computed: {
    itemMenuInterno() {
      return this.$router.options.routes[0].children;
    },
    perfilUsuario() {
      return JSON.parse(localStorage.getItem("perfilId"));
    },
    linkSidebar() {
      return this.itemMenuInterno.filter(item => item.meta.perfilPermitido.includes(this.perfilUsuario));
    },
    menuAtivo() {
      return this.$store.state.interacoes.menuAtivado;
    }
  },
  methods: {
    toggleMenu() {
      return this.$store.commit("interacoes/SET_SIDEBAR", !this.menuAtivo);
    }
  }
};
</script>

<style lang="scss">
.container-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: 25px 0 60px 0;
  transition: 0.3s;
}
.menu-lateral-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 35px;
  height: 35px;
  cursor: pointer;
  transform: translateX(-50%);
  span {
    background-color: $cor-tema;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 5px;
    transition: background 0.3s;
    transform: translateY(-50%);
    border-radius: 8px;
    &:before,
    &:after {
      background-color: $cor-tema;
      position: absolute;
      left: -3px;
      width: 60%;
      height: 5px;
      content: "";
      transition: 0.3s;
      border-radius: 8px;
    }
    &:before {
      top: -6px;
      transform: rotate(-45deg);
    }
    &:after {
      top: 6px;
      transform: rotate(45deg);
    }
  }
  &.menu-lateral-fechado span {
    &:before,
    &:after {
      left: 17px;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
</style>
