<template>
  <div id="main" :class="layout">
    <transition name="fade">
      <Loading />
    </transition>
    <MensagemAlerta />
    <ModalRetorno />
    <MotivoBloqueio />
    <ModerarPremio />
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>


<script>
import Loading from "@/components/loading/Loading.vue";
const MensagemAlerta = () => import("@/components/modal/MensagemAlerta.vue");
const ModalRetorno = () => import("@/components/modal/ModalRetorno.vue");
const MotivoBloqueio = () => import("@/components/modal/MotivoBloqueio.vue");
const ModerarPremio = () => import("@/components/modal/ModerarPremio.vue");
import { autenticar2fa } from "@/api/auth";

export default {
  components: {
    Loading,
    MensagemAlerta,
    ModalRetorno,
    MotivoBloqueio,
    ModerarPremio
  },
  computed: {
    layout() {
      return this.$store.state.interacoes.layout;
    },
    token() {
      return localStorage.getItem("token")
    }
  },
  mounted() {
    this.verificarToken2FA();
  },
  methods: {
    verificarToken2FA() {
      if (this.token) {
        localStorage.setItem('token', this.token);
        autenticar2fa(this.token)
      }
    }
  }
};
</script>