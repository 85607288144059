import axios from "axios";
import store from "@/store";
import router from "@/router";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_BASE,
});

function exibirAlertas(error) {
  if (error.response) {
    if (401 === error.response.status) {
      store.commit("interacoes/SET_ALERTA", {
        ativado: true,
        mensagem: "Sua sessão expirou, faça login novamente",
        tipo: "alerta",
      });
      localStorage.removeItem("token");
      store.commit("user/SET_LOGADO", false);
      router.push("/login");
    }
  } else {
    store.commit("interacoes/SET_ALERTA", {
      ativado: true,
      mensagem: "O servidor encontrou um erro. Tente novamente mais tarde",
      tipo: "alerta",
    });
  }
}

client.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  // config.headers["Access-Control-Allow-Origin"] =
  //   "*.promocaosopranofullbar.com.br";
  return config;
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    exibirAlertas(error);
    return Promise.reject(error);
  }
);

export default client;
