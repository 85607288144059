<template>
  <div class="field">
    <slot></slot>
    <transition name="fade">
      <span class="input-error" role="alert" v-if="erro != ''">{{ erro }}</span>
    </transition>
  </div>
</template>

<script>
export default {
  props: ["erro"]
};
</script>
