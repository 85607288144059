<template>
  <header id="header">
    <div class="header-img">
      <router-link to="/" style="height: 100%; width: auto;">
        <transition mode="out-in" name="fade-left">
          <component :is="logoEmpresa" style="height: 100%; width: fit-content;"/>
        </transition>
      </router-link>
    </div>
    <div class="btn-header">
      <!-- <div class="header-layout">
        <input id="modo-layout" type="checkbox" v-model="modoLayout" />
        <label class="toggleLayout" for="modo-layout" :class="{ active: modoLayout }"></label>
      </div> -->
      <div class="header-user">
        <a @click="deslogar">Sair</a>
      </div>
    </div>
  </header>
</template>

<script>
import LogoPositivo from "@/components/logos/LogoPositivo.vue";
import LogoNegativo from "@/components/logos/LogoNegativo.vue";
export default {
  components: {
    LogoPositivo,
    LogoNegativo
  },
  data() {
    return {
      modoLayout: false
    };
  },
  computed: {
    corLayout() {
      return this.modoLayout == false ? "layout-claro" : "layout-escuro";
    },
    logoEmpresa() {
      return this.modoLayout == false ? "LogoPositivo" : "LogoNegativo";
    }
  },
  methods: {
    deslogar() {
      this.$store.commit("user/SET_LOGADO", false);
      this.$store.commit("user/SET_PERFIL", "");
      this.$store.commit("user/SET_ID_USUARIO", "");
      window.localStorage.removeItem("token");
      this.$router.push("/login");
    },
    setarLayout() {
      const modoLayout = window.localStorage.getItem("corLayout");
      if (modoLayout) {
        if (modoLayout == "layout-claro") {
          this.modoLayout = false;
          return this.$store.commit("interacoes/SET_TEMA", modoLayout);
        } else {
          this.modoLayout = true;
          return this.$store.commit("interacoes/SET_TEMA", modoLayout);
        }
      }
    }
  },

  mounted() {
    this.$store.commit("interacoes/SET_SIDEBAR", true);
    this.setarLayout();
  },
  watch: {
    corLayout() {
      window.localStorage.setItem("corLayout", this.corLayout);
      return this.$store.commit("interacoes/SET_TEMA", this.corLayout);
    },
    setarLayout() {
      this.$forceUpdate();
    },
    deep: true
  }
};
</script>

<style lang="scss">
#header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  transition: 0.3s;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  .header-img {
    position: relative;
    box-sizing: border-box;
    width: 250px;
    height: 100%;
    padding: 5px 15px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
    img {
      max-width: 200px;
      height: auto;
      max-height: 100%;
      pointer-events: none;
    }
  }
  @media (max-width: 700px) {
    height: 55px;
    .header-img {
      width: 220px;
      img {
        max-width: 180px;
      }
    }
  }
  @media (max-width: 380px) {
    .header-img {
      width: 190px;
      img {
        max-width: 150px;
      }
    }
  }
}
.btn-header {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 20px;
  transition: 0.3s;
  background: none !important;
  @media (max-width: 340px) {
    padding-left: 20px;
  }
}
.header-layout {
  input {
    display: none;
  }
  .toggleLayout {
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 60px;
    height: 35px;
    padding: 10px;
    cursor: pointer;
    border: 1px solid $cinza-interno;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    &:after {
      position: absolute;
      top: 50%;
      left: 5px;
      box-sizing: border-box;
      width: 25px;
      height: 25px;
      content: "";
      transition: 0.3s;
      transform: translateY(-50%);
      border-radius: 50%;
    }
    &.active:after {
      left: calc(100% - 30px);
    }
  }
}
.header-user {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 40px 0 30px;
  a {
    cursor: pointer;
    text-decoration: underline;
  }
  @media (max-width: 700px) {
    margin: 0 20px 0 15px;
  }
}
</style>
