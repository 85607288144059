import client from "@/api";
import store from "@/store";
import router from "@/router";

// Autenticacao
export async function autenticar2fa(data) {
    try {
      store.commit("interacoes/SET_LOADING", true);
  
       const res = await client.post('/Autenticacao/Autenticar2FA', data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      });

      window.localStorage.setItem("perfilId", res.data.perfilId);
      window.localStorage.setItem("token", res.data.token);
      router.push({ name: "inicio" });
    } catch (erro) {
        store.commit("interacoes/SET_MODAL_RETORNO", {
            tituloModal: erro.response.data.titulo,
            mensagemModal: erro.response.data.mensagem,
            tipoModal: "error",
        });

        localStorage.removeItem("token");
        router.push({ name: "login" });
    } finally {
      store.commit("interacoes/SET_LOADING", false);
    }
  }
  