<template>
    <form class="formulario-login" @submit.prevent="fazerLogin">
        <InputValidate :erro="emailLoginErro">
            <label for="email">E-mail</label>
            <input type="text" name="email" placeholder="Insira seu e-mail" v-model.trim="login.email"
                @blur="$v.login.email.$touch()" @keyup.enter="enterParaLogin" />
        </InputValidate>
        <InputValidate :erro="senhaLoginErro">
            <label for="senha">Senha</label>
            <input type="password" name="senha" placeholder="Insira sua senha" v-model.trim="login.senha"
                @blur="$v.login.senha.$touch()" @keyup.enter="enterParaLogin" />
        </InputValidate>
        <button type="submit" :disabled="$v.login.$invalid" class="btn btn--primario login__btn" style="margin-top: 40px;">
            Acessar
        </button>
    </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { emailLoginErro, senhaLoginErro } from "@/plugins/vuelidate/login";
import { enviarLogin } from "@/api/login/login";
import InputValidate from "@/components/formularios/InputValidate.vue";

export default {
    name: "formulario-login",
    components: {
        InputValidate,
    },
    data() {
        return {
            login: {
                email: "",
                senha: "",
            },
        };
    },
    validations: {
        login: {
            email: {
                required,
                email,
            },
            senha: {
                required,
            },
        },
    },
    computed: {
        emailLoginErro,
        senhaLoginErro,
    },
    methods: {
        enterParaLogin() {
            this.$v.login.$invalid ? false : this.fazerLogin();
        },
        async fazerLogin() {
            const formData = new FormData();
            formData.append(`Email`, this.login.email);
            formData.append(`Senha`, this.login.senha);

            await enviarLogin(formData);
        },
    },
};
</script>

<style lang="scss">
.field {
    label {
        font-weight: 700;
    }
}
</style>