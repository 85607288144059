<template>
  <section class="login">
    <div class="login__modal">
      <LogoEmpresa class="login__img" />
      <p class="login__instrucao">
        Preencha os campos abaixo, clique em<br />
        acessar e siga as próximas instruções
      </p>
      <transition mode="out-in" name="fade-left" appear>
        <FormularioLogin/>
      </transition>
    </div>
    <div class="login__copy">
      <span>Powered by</span>
      <img src="@/assets/images/logos/logo-fullbar.png" alt="Logo Fullbar" />
    </div>
  </section>
</template>

<script>
import FormularioLogin from "@/components/formularios/FormularioLogin.vue";
import LogoEmpresa from "@/components/logos/LogoPositivo.vue";
import img from "@/assets/images/tema/logo-empresa.shot.svg";
export default {
  name: "login",
  components: {
    FormularioLogin,
    LogoEmpresa,
  },
  data() {
    return {
      imagem: img,
    };
  },
};
</script>

<style lang="scss">
.login {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F7F7F7;

  &__modal {
    padding: 20px;
    width: 100%;
    max-width: 430px;
    margin: auto;
  }

  &__img {
    display: block;
    margin: 0 auto 40px auto;
  }

  &__instrucao {
    text-align: center;
    margin-bottom: 40px;
  }

  &__copy {
    background-color: #E3E3E3;
    padding: 42px 20px;
    text-align: center;
    font-weight: 600;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
