<template>
  <div class="loading" v-if="loading">
    <div class="ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    loading() {
      return this.$store.state.interacoes.loading;
    },
  }
};
</script>

<style lang="scss" scoped>
.loading {
  top: 0;
  position: fixed;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: rgba($branco, 0.8);
  backdrop-filter: blur(2px);

  .ellipsis {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 80px;

    div {
      position: absolute;
      top: 33px;
      width: 15px;
      height: 15px;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
      border-radius: 50%;
      background: $cor-tema;

      &:nth-child(1) {
        left: 8px;
        animation: scaleUp 0.6s infinite;
      }

      &:nth-child(2) {
        left: 8px;
        animation: slideInLeft 0.6s infinite;
      }

      &:nth-child(3) {
        left: 32px;
        animation: slideInLeft 0.6s infinite;
      }

      &:nth-child(4) {
        left: 56px;
        animation: scaleDown 0.6s infinite;
      }
    }
  }

  @keyframes scaleUp {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }

  @keyframes scaleDown {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes slideInLeft {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }
}</style>
