<template>
  <router-link class="link-sidebar" :to="link" exact>
    <span class="link-icone">
      <component :is="icone" />
    </span>
    <span class="link-texto" :class="[menuAtivo]">
      {{ texto }}
    </span>
  </router-link>
</template>

<script>
import IconInicio from "@/components/logos/IconInicio.vue";
import IconComunicacao from "@/components/logos/IconComunicacao.vue";
import IconCupom from "@/components/logos/IconCupom.vue";
import IconFaleConosco from "@/components/logos/IconFaleConosco.vue";
import IconNumeroSorte from "@/components/logos/IconNumeroSorte.vue";
import IconRelatorioUsuarios from "@/components/logos/IconRelatorioUsuarios.vue";
import IconPremiacaoInstantanea from "@/components/logos/IconPremiacaoInstantanea.vue";
import IconConfiguracoes from "@/components/logos/IconConfiguracoes.vue";
export default {
  props: ["texto", "link", "icone"],
  components: {
    IconInicio,
    IconComunicacao,
    IconCupom,
    IconFaleConosco,
    IconNumeroSorte,
    IconRelatorioUsuarios,
    IconPremiacaoInstantanea,
    IconConfiguracoes
  },
  computed: {
    menuAtivo() {
      return this.$store.state.interacoes.menuAtivado ? 'ativo' : '';
    }
  }
};
</script>

<style lang="scss">
.link-sidebar {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  margin: 5px 0;
  transition: 0.3s;
  text-align: center;
  text-decoration: none;
  color: $cor-escura;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 60px;
    height: 100%;
    content: "";
    transition: 0.3s;
    opacity: 0;
    border-radius: 0 18px 18px 0;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }

  &:after {
    position: absolute;
    top: 0;
    left: 65px;
    visibility: hidden;
    width: 215px;
    height: 100%;
    content: "";
    transition: 0.3s;
    opacity: 0;
    border-radius: 18px 0 0 18px;
  }

  .link-icone {
    position: relative;
    z-index: 1;
    display: block;
    width: 60px;
    min-width: 60px;

    svg,
    img {
      display: block;
      max-width: 22px;
      height: auto;
      max-height: 40px;
      margin: 0 20px;
    }

    .elementos-internas {
      transition: 0.3s;
    }
  }

  .link-texto {
    @include font-style(600, $sizeMob: 18);
    display: ruby;
    position: relative;
    z-index: 1;
    width: 210px;
    transition: 0.3s;
    text-align: left;
    color: $cinza-interno;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    font-weight: 600;
    word-break: keep-all;

    &.ativo {
      visibility: visible;
      opacity: 1;
    }
  }

  &:hover {
    &:before {
      visibility: visible;
      opacity: 1;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    .link-texto {
      padding-left: 10px;
    }
  }

  &.active {
    &:before {
      visibility: visible;
      opacity: 1;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    }

    &:after {
      visibility: visible;
      opacity: 1;
    }

    .link-texto {
      padding-left: 20px;
    }
  }
}
</style>
