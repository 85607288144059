import client from "@/api";
import store from "@/store";
// Caminhos
import { _rotaLogin } from "@/api/_caminhosApi";

// Login
export function enviarLogin(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${_rotaLogin()}`, data)
    .then((resp) => {
      store.commit("interacoes/SET_MODAL_RETORNO", {
        tituloModal: resp.data.titulo,
        mensagemModal: resp.data.mensagem,
        tipoModal: "sucesso",
      });
    })
    .catch((erro) => {
      store.commit("interacoes/SET_MODAL_RETORNO", {
        tituloModal: erro.response.data.titulo,
        mensagemModal: erro.response.data.mensagem,
        tipoModal: "error",
      });
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
